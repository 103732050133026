<template>
  <div>
    <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini && $vuetify.breakpoint.mdAndUp"
        app
        fixed
        :permanent="$vuetify.breakpoint.mdAndUp"
        :temporary="!$vuetify.breakpoint.mdAndUp"
    >

      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar :size="mini ? 46 : 64">
            <v-img src="../../../assets/avatar_01.jpg"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="title">
              <b>Jarosław Tarczyński</b>
            </v-list-item-title>
            <v-list-item-subtitle>Mecenas</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
            v-for="[icon, text, route] in links"
            :key="icon"
            v-on:click="$router.push({name: route})"
            link
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$vuetify.breakpoint.mdAndUp" link>
          <v-list-item-content>
            <v-btn
                icon
                @click.stop="mini = !mini"
            >
              <v-icon>{{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-card>
      <v-toolbar
          color="green lighten-1"
          app
          class="elevation-1"
      >
        <v-app-bar-nav-icon v-if="!$vuetify.breakpoint.mdAndUp" v-on:click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-heart</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  data: () => ({
    cards: ['Today', 'Yesterday'],
    mini: false,
    drawer: false,
    links: [
      ['mdi-apps', 'Kokpit', 'cockpit'],
      ['mdi-calendar-account', 'Mój kalendarz', 'calendar'],
      ['mdi-cogs', 'Ustawienia', 'cockpit'],
    ],
  }),
}
</script>

<style scoped>

</style>