<template>
  <v-app id="inspire">
    <SideMenu/>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import SideMenu from "@/components/pages/cockpit/SideMenu";

export default {
  name: "CockpitPage",
  components: {SideMenu},
  data: () => ({

  }),
}
</script>

<style scoped>

</style>