import Vue from 'vue';
import VueRouter from 'vue-router'
import LoginPage from "@/components/pages/LoginPage";
import CockpitPage from "@/components/pages/CockpitPage";
import CalendarPage from "@/components/pages/cockpit/CalendarPage";
import SummaryPage from "@/components/pages/cockpit/SummaryPage";

Vue.use(VueRouter);

const routes = [
    {path: '/', component: LoginPage},
    {
        path: '/cockpit',
        component: CockpitPage,
        children: [
            {
                path: 'summary',
                name: 'cockpit',
                component: SummaryPage
            },
            {
                path: 'calendar',
                name: 'calendar',
                component: CalendarPage
            },
        ]
    }
];

export default new VueRouter({
    routes
})