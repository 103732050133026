<template>
  <v-container
      class="py-8 px-6"
      fluid
  >
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Statystyki:</v-card-title>

          <v-card-text>
            <v-row :justify="$vuetify.breakpoint.mdAndUp ? '' : 'center'">
              <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>
              <v-col md="2" sm="12" cols="12" :style="!$vuetify.breakpoint.mdAndUp ? 'text-align: center' : ''">
                <v-progress-circular
                    :rotate="360"
                    :size="$vuetify.breakpoint.mdAndUp ? 250 : 200"
                    :width="25"
                    :value="90"
                    color="teal"
                    style="font-size: 29px"
                >
                  <b>148</b>
                </v-progress-circular>
              </v-col>
              <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>
              <v-col md="9" sm="12" cols="12">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td>
                        Czas roboczy w miesiącu:
                      </td>
                      <td>
                        158h
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Ilość przepracowanych godzin:
                      </td>
                      <td>
                        148h
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Pozostało godzin:
                      </td>
                      <td>
                        <b>10h</b>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>


      <v-expansion-panels
          v-model="panel"
          multiple
      >
        <v-col
            v-for="card in cards"
            :key="card"
            cols="12"
        >
          <v-card>
            <v-expansion-panel :key="card">
              <v-expansion-panel-header>{{ card }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list two-line>
                  <template v-for="n in 30">
                    <v-list-item
                        dense
                        :key="n"
                    >
                      <v-list-item-avatar color="green darken-1">
                        7.5
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>Dzień: {{ 31 - n }}</v-list-item-title>

                        <v-list-item-subtitle>
                          Przepracowane: 7h 30m, Brak: 0h 30m
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider
                        :key="`divider-${n}`"
                        inset
                    ></v-divider>
                  </template>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-card>
        </v-col>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SummaryPage",
  data: () => ({
    cards: ['Luty 2021', 'Styczeń 2021', 'Grudzień 2020', 'Listopad 2020', 'Październik 2020', 'Wrzesień 2020'],
    drawer: null,
    links: [
      ['mdi-apps', 'Kokpit', 'cockpit'],
      ['mdi-calendar-account', 'Mój kalendarz', 'calendar'],
      ['mdi-cogs', 'Ustawienia', 'cockpit'],
    ],
  }),
}
</script>

<style scoped>

</style>