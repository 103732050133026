<template>
  <v-app>
    <v-main>
      <v-container>
        <v-btn v-on:click="$router.push('/cockpit')">Login</v-btn>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "LoginPage"
}
</script>

<style scoped>

</style>